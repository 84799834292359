import React, { FunctionComponent, useEffect, useState } from 'react';
import cn from 'classnames';

import AirportsSelect from './AirportsSelect';
import RoomsSelect from './RoomsSelect';
import DurationSelect from './DurationSelect';
import './SearchBar.scss';

import useMultipleMedia from '../../hooks/useMultipleMedia';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import BoardTypeSelect from './BoardType';
import { noop } from 'lodash';
import { siteConfiguration } from '../../hooks/useRequest';
import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';
import { useSearchProvider } from '../../context/search';

import flightHotelIcon from '../assets/flightHotel.svg';
import cityIcon from '../assets/city.svg';
import packageIcon from '../assets/pack.svg';

import search from '../../components/assets/search-icon.svg';
import searchDark from '../../components/assets/icons/dark/noun-magnifying-glass-202232-222222.svg';

import { fbqTrackSearch } from '../../hooks/fb';

import { LoaderSpinner } from '../Loader/jLoader'
import dayjs from 'dayjs';

import { SearchSelectionRoom, SearchSelections } from '../../hooks/types'

import { roomsFromPaxString } from '../../services/pax';
import { geoArrayFromString, departurePointArrayFromString } from '../../services/geo';
import { getRoomsParamsString, getDepartingParamsString, getDurationParamString, getBoardParamsString, getDepartingTypeParamsString, getOfferIDParamsString, getDeparturePointsParamsString, numberArrayFromString  } from '../../services/search'



interface SearchBarProps {
  localSession?: string;
  accommodationId?: number;
  resultFilters?: any;
  typeOfSearch?: number;
  onDeparturePointChange: (departurePoints: string[]) => any;
  onDurationChange: (departurePoints: number) => any;
  onBoardChange: (departurePoints: number[]) => any;
  onRoomChange: (departurePoints: SearchSelectionRoom[]) => any;
}



const Refine: FunctionComponent<SearchBarProps> = ({
  accommodationId,
  typeOfSearch = 0,
  onDeparturePointChange,
  onDurationChange,
  onBoardChange,
  onRoomChange
}) => {


  const defaultSelections: SearchSelections = { 
    duration: 7, destinations: [], regions: [], resorts: [], 
    departurePoints: [], departureDate: null, departureDateType: 0, searchType: 0, ratings: [], tripRatings: [], boards: [], rooms : [{adults: 2, children: 0, childAges: []}]}


  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  if(searchParams != null){
    if(searchParams.get('departing') != null){
      defaultSelections.departureDate = searchParams.get('departing') + ' 00:00';
    }   
    
    if(searchParams.get('departingType') != null){
      defaultSelections.departureDateType = Number(searchParams.get('departingType'));
    }

    if(searchParams.get('rooms') != null){
      defaultSelections.rooms = roomsFromPaxString(searchParams.get('rooms') || '');
    }
    if(searchParams.get('duration') != null){
      defaultSelections.duration = Number(searchParams.get('duration'));
    }

    if(searchParams.get('destinations') != null){
      defaultSelections.destinations = geoArrayFromString(searchParams.get('destinations'));
    }

    if(searchParams.get('regions') != null){
      defaultSelections.regions = geoArrayFromString(searchParams.get('regions'));
    }

    if(searchParams.get('resorts') != null){
      defaultSelections.resorts = geoArrayFromString(searchParams.get('resorts'));
    }

    if(searchParams.get('departurePoints') != null){
      defaultSelections.departurePoints = departurePointArrayFromString(searchParams.get('departurePoints'));
    }    
    
    if(searchParams.get('boards') != null){
        defaultSelections.boards = numberArrayFromString(searchParams.get('boards') || '');
    }


  }

  const departingType = '&departingType=0';


  const [selectedSearchParams, setSelectedSearchParams] = useState(defaultSelections)


  const [clSearchType, setCliS] = useState<number>(0);
  const [maxGuestsMessage, setMaxGuestsMessage] = useState<string | null>(null);
  const [overrideOverlay, setOverrideOverlay] = useState<boolean>(false);
  const [option, setOption] = useState(typeOfSearch);

  const [paxVal, setPaxVal] = useState<{ valid: boolean; message: string }>();

  const [ready, setReady] = useState<boolean>(true);

  const [searchAtt, setSearchAtt] = useState<boolean>(false);

  const navigate = useNavigate();
  const { state } = useSearchProvider();
  const { state: searchState, dispatch } = useSearchProvider();



  const {
    RootStore: {
      configuration
    },
  } = useStores() as { RootStore: RootStore };

  useEffect(() => {

    // if (isHolidayPage) {
    //   setReady(true);
    //   return
    // }
    setSelectedSearchParams(defaultSelections)


  }, [searchParams]);



  const boardOptionFromString = (x: string) => {
    switch (x.toLowerCase()) {
      case 'all inclusive':
        return { label: 'All inclusive', value: 5 };
      case 'full board':
        return { label: 'Full board', value: 4 };
      case 'half board':
        return { label: 'Half board', value: 3 };
      case 'bed & breakfast':
        return { label: 'Bed & breakfast', value: 2 };
      case 'self catering':
        return { label: 'Self catering', value: 1 };
      case 'room only':
        return { label: 'Room only', value: 0 };
    }
  };

  function setZIndex(elem: string) {
    const child = document.getElementById(elem);

    if(child == null){
      return
    }

    const parentWithClass = child.closest('.stack') as HTMLElement;
    if(parentWithClass == null){
      return
    }

    parentWithClass.style.zIndex = '999';
  }

  useEffect(() => {
    dispatch({
      type: 'set',
      payload: {
        ...searchState,
        option,
      },
    });
  }, [option]);


  function handleDeparturePointParams(selectedValues:string[]){
    let sp = selectedSearchParams;
    sp.departurePoints = selectedValues;
    setSelectedSearchParams(sp);

    onDeparturePointChange(selectedValues);
  }

  function handleDurationParam(option:number){

    let sp = selectedSearchParams;
    sp.duration = option;
    setSelectedSearchParams(sp);

    onDurationChange(option);
  }

  function handleBoardParams(selectedValues:number[]){


    let sp = selectedSearchParams;
    sp.boards = selectedValues;
    setSelectedSearchParams(sp);

    onBoardChange(selectedValues);
  }

  function handleRoomsParam(selectedValues:SearchSelectionRoom[]){


    let sp = selectedSearchParams;
    sp.rooms = selectedValues;
    setSelectedSearchParams(sp);

    onRoomChange(selectedValues);
  }


  const validateInputs = (searchAttempt: boolean) => {
    let valid = true;

    const valDepAir = {valid: true, message: ''};
    const valPax = {valid: true, message: ''};

  // Always validate children ages (for any type of search)
  if (selectedSearchParams.rooms) {
  let noChildAge = 0;
  const rooms = selectedSearchParams.rooms as any;
  for(const x of rooms){
    const childAgesArray = x.childAges;
    for(const ca of childAgesArray){
      if(Number(ca) < 0){
        noChildAge++;
        break;
      }
    }
  }
  if(noChildAge > 0){
    valid = false;
    valPax.valid = false
    if(noChildAge == 1){
      valPax.message = 'Please select your childs age on return';
    }
    else{
      valPax.message = 'Please select your childrens ages on return';
    }
  }
  else{
    valPax.valid = true
    valPax.message = '';
  }
  }
  
  if(searchAtt || searchAttempt){
    setPaxVal(valPax);
  }
  return valid
}

  let rooms = JSON.stringify(['2']);
  let numberOfRooms = 1;
  if (selectedSearchParams) {
    if (selectedSearchParams.rooms) {
      rooms = JSON.stringify(selectedSearchParams.rooms);
      numberOfRooms = selectedSearchParams.rooms.length;
    }
  }


  setZIndex('root');

  let buttonIco = search;
  const buttonIcoMob = search;

  if(configuration.button_icon == 'dark'){
    buttonIco = searchDark;
  }


  if(process.env.REACT_APP_BUILD_TARGET === 'widget'){
    if(configuration.spec == '6yk1y'){;
      buttonIco = searchDark;
    }
  }


  if(!ready){

    const loader =<><div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      {LoaderSpinner()}</div></>;

    return loader;
  }




  return (
    <>
      <div className='search-bar-container'>
        <div className='collapsed-search-options'>

          <AirportsSelect
            clickApplyHandler={handleDeparturePointParams}
            accommodationId={accommodationId}
            selectedSearchParams={selectedSearchParams}
            onActivate={() => setOverrideOverlay(true)}
            showIcons={true}
            checkDate={true}
          />


          <DurationSelect
            clickApplyHandler={handleDurationParam}
            selectedSearchParams={selectedSearchParams}
            accommodationId={accommodationId}
            onActivate={() => setOverrideOverlay(true)}
            showIcons={true}
            checkDate={true}
          />


            <BoardTypeSelect
                selectedSearchParams={selectedSearchParams}
                accommodationId={accommodationId}
                clickApplyHandler={handleBoardParams}
                showIcons={true}
                checkDate={true}
            />


          <RoomsSelect
            validationError={paxVal}
            clickApplyHandler={(value) => {
              if (value) {
                handleRoomsParam(value);
              }

              if (maxGuestsMessage) {
                setMaxGuestsMessage(null);
              }
            }}
            accommodationId={accommodationId}
            selectedSearchParams={selectedSearchParams}
            onActivate={() => setOverrideOverlay(true)}
            showIcons={true}
            roomNumber={numberOfRooms}
            checkDate={true}
            saveSelectionToSession={false}
          />

        </div>
      </div>

      {maxGuestsMessage && <p className='search-bar-error'>{maxGuestsMessage}</p>}
    </>
  );
};

export default Refine;
