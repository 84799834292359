import { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import SearchButton from '../SearchBar/SearchButton';
import cn from 'classnames';

import './Header.css';
import { Logo } from '../Logo/Logo';
import { Heart } from '../HeartIcon/Heart';
import { HeaderIcon } from '../HeaderIcon/HeaderIcon';

import MenuIcon from '../assets/burger.svg';
import AccountIcon from '../assets/account.svg';
import { ProgressBar, StepState } from '../ProgressBar/ProgressBar';
import { SuccessIcon } from './SuccessIcon/SuccessIcon';

import atolIcon from '../assets/atol.svg';
import atbaIcon from '../assets/atba.svg';

import atolIconDefault from '../assets/icons/default/01/TOL.svg';
import atbaIconDefault from '../assets/icons/default/01/ABTA.svg';

import telIco from '../assets/telico.svg'


import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';

import { getCookie } from '../../services/session';


interface HeaderProps {
  searchParams: any; // TODO Fix typing
  progressBar?: StepState[];
  isOverlayOpen?: boolean;
  onSearchButtonClick: () => void;
  onSearchItemClick: (type: string) => void;
  isBookingConfirmation?: boolean;
  isBookingConfirmed?: number;
  isNavigationVisible?: boolean;
  telephoneNumber: string;
  callMessage?: string;
}

export const Header: FunctionComponent<HeaderProps> = ({
  onSearchButtonClick,
  onSearchItemClick,
  isOverlayOpen,
  progressBar,
  searchParams,
  isBookingConfirmation = false,
  isBookingConfirmed = 1,
  isNavigationVisible = true,
  telephoneNumber= '08004084048',
  callMessage = 'Call to speak to one of our experts'
}: HeaderProps) => {
  
  const location = useLocation();
  const {
    RootStore: {
      configuration
    },
  } = useStores() as { RootStore: RootStore };


  let atolIco = atolIconDefault;
  let abtaIco = atbaIconDefault;

  if(configuration.icon_set == 'blue'){
    atolIco = atolIcon;
    abtaIco = atbaIcon;
  }

  let headIcoSecClass = 'header-icons-section';
  let mnobRightSec = null;
  let headRightSec = <div className='booking-confirmed-icons'>
  <div className='img-wrapper header__abta-wrap'>
    <img src={abtaIco} alt='Atba logo' />
  </div>
  <div className='header__atol-wrap img-wrapper'>
    <img src={atolIco} alt='Atol logo' />
    <span className='header__atol-text'>
      ATOL
      <br />
      Protected
    </span>
  </div>
</div>


if(configuration.spec == '6yk1y'){
  // 08004084048
  const strTel = 'tel:' + telephoneNumber;
  let formatNum = telephoneNumber;
  if(telephoneNumber.length > 7){
    formatNum = telephoneNumber.substring(0, 4) + ' ' + telephoneNumber.substring(4, 7) + ' ' + telephoneNumber.substring(7);
  }


  headIcoSecClass = 'header-icons-section header-icons-section-wrap'
  headRightSec = <><img src={telIco} style={{width: '35px'}}></img><p className='telephone'><a href={strTel}>{formatNum}</a></p>
  
  <p className='telType'>{callMessage}</p>
  </>
  
  mnobRightSec = <a href={strTel} style={{cursor: 'pointer'}}><img src={telIco} style={{width: '35px'}}></img></a>;


}



  return (
    <>
      <header style={{color: configuration.text_colour}} className={cn('hwt-header header', { noneBorder: isBookingConfirmation })}>
        <div className='wrapper'>
          <div className={'header-logo'}>
            <Logo width={163} height={53} route={'/'} configuration={configuration} />
          </div>

            <div className='main-search'>
              {isBookingConfirmation ? (
                <SuccessIcon confirmed={isBookingConfirmed} />
              ) : (
                <>
                  {!progressBar && !isOverlayOpen && searchParams && (
                    <SearchButton
                      isOverlayOpen={isOverlayOpen}
                      onClick={onSearchButtonClick}
                      onSearchItemClick={onSearchItemClick}
                    />
                  )}
                </>
              )}
            </div>
          

          <div className={headIcoSecClass}>
            {/* <div className='booking-confirmed-icons'>
              <div className='img-wrapper header__abta-wrap'>
                <img src={abtaIco} alt='Atba logo' />
              </div>
              <div className='header__atol-wrap img-wrapper'>
                <img src={atolIco} alt='Atol logo' />
                <span className='header__atol-text'>
                  ATOL
                  <br />
                  Protected
                </span>
              </div>
            </div> */}
            {headRightSec}
          </div>



        </div>
        <div className={'mobile-wrapper'}>
          <div className={'header-icons-section'}>
            {/* {!isBookingConfirmation && <Heart width={34} height={30} numberFavourites={0} />} */}
            <></>
          </div>
          <div className={'header-logo'}>
            <Logo width={129} height={40} route={'/'} configuration={configuration} />
          </div>
          <div className={'header-icons-section'}>
            {mnobRightSec}
          </div>
        </div>
      </header>
      {progressBar && (
        <div className='progress-bar-container'>
          <ProgressBar state={progressBar} />
        </div>
      )}
    </>
  );
};
