import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';

// @ts-ignore
import { gtag, install } from 'ga-gtag';

import { NotFoundPage } from './pages/NotFound/NotFound';

import { HomePage } from './pages/HomePage/HomePage';
import { PreFilterPage } from './pages/PreFilter/PreFilter';
import { ExpirePage } from './pages/ExpiredPage/ExpiredPage';

import SearchResultsPage from './pages/SearchResultsPage/SearchResultsPage';
import PackageSearchResultsPage from './pages/PackageSearchResultsPage/PackageSearchResultsPage';
import HolidayPage from './pages/HolidayPage/HolidayPage';
import ExtrasPage from './pages/ExtrasPage/ExtrasPage';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'mobx-react';

import { RootStore } from './stores/RootStore';
import PassengerDetailsPage from './pages/PassengerDetailsPage/PassengerDetailsPage';
import PaymentPage from './pages/PaymentPage/PaymentPage';
import BookingConfirmed from './pages/BookingConfirmed/BookingConfirmed';
import SelectPage from './pages/SelectPage/SelectPage';

import PackageSelectPage from './pages/SelectPagePackage/SelectPagePackage';

// fonts
import './fonts/GothamSSm-Black_Web.woff';
import './fonts/GothamSSm-BlackItalic_Web.woff';
import './fonts/GothamSSm-Bold_Web.woff';
import './fonts/GothamSSm-BoldItalic_Web.woff';
import './fonts/GothamSSm-Book_Web.woff';
import './fonts/GothamSSm-BookItalic_Web.woff';
import './fonts/GothamSSm-Light_Web.woff';
import './fonts/GothamSSm-LightItalic_Web.woff';
import './fonts/GothamSSm-Medium_Web.woff';
import './fonts/GothamSSm-MediumItalic_Web.woff';
import './fonts/GothamSSm-XLight_Web.woff';
import './fonts/GothamSSm-XLightItalic_Web.woff';
import { SearchProvider } from './context/search';
import { PackagePage } from './pages/PackagePage/PackagePage';
import { WidgetLander } from './pages/WidgetLander';
import { PaymentInterstitialPage } from './pages/PaymentInterstitial/PaymentInterstitial';
import { BookingFailedPage } from './pages/BookingFailed/BookingFailed';

import {siteSign, clientConfiuration} from '../src/hooks/useRequest'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const headSection = document.getElementById('headSection');

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: 'pre-search',
    element: <PreFilterPage />,
  },  
  {
    path: 'expired',
    element: <ExpirePage />,
  },
  {
    path: 'search',
    element: <SearchResultsPage />,
  },
  {
    path: 'package-search',
    element: <PackageSearchResultsPage />,
  },
  {
    path: 'holiday/:hashid',
    element: <ExpirePage />,
  },
  {
    path: 'flight-and-hotel/:destination/:region/:resort/:hotelname/:accid',
    element: <HolidayPage />,
  },
  {
    path: 'package/:destination/:region/:resort/:hotelname/:accid',
    element: <PackagePage />,
  },
  {
    path: 'extras',
    element: <ExtrasPage />,
  },
  {
    path: 'select',
    element: <SelectPage />,
  },
  {
    path: 'package-select',
    element: <PackageSelectPage />,
  },
  {
    path: 'passengers',
    element: <PassengerDetailsPage />,
  },
  {
    path: 'payment',
    element: <PaymentPage />,
  },
  {
    path: 'booking-confirmed',
    element: <BookingConfirmed />,
  },
  {
    path: 'booking-failed',
    element: <BookingFailedPage />,
  },
  {
    path: 'threedresp',
    element: <PaymentInterstitialPage />,
  },
]);
const rootStore = new RootStore();

const widgetRouter = createBrowserRouter([
  {
    path: '*',
    element: <WidgetLander />,
  },
]);


// siteSign().then((token : any) => {
    // perhaps we should do gtag here ? 
    if (process.env.REACT_APP_BUILD_TARGET === 'widget') {
      const dsrc = document?.currentScript as HTMLScriptElement;

      let scriptName = '';
      if(dsrc != null){
        scriptName = dsrc.src;
      }
      else{
         root.render(null);
      }

      let widgetDomain = process.env.REACT_APP_WIDGET_HOST;
      if(widgetDomain == null){
        widgetDomain = 'https://widget.holidaywebtech.co.uk/static/js/main.js?uid';
      }

      const urlParams  = new URLSearchParams(scriptName)
      const uid = urlParams.get(widgetDomain);
      const st = urlParams.get('st');
      const shwtb = urlParams.get('shwtb');
      const agtusr = urlParams.get('agtusr');
      const aff = urlParams.get('aff');
      // urlParams.forEach((value, key) => {
      //   console.log(value, key);
      //   const xx = value;
      //   const xxx = key;
      // });
      // TEMP ! provide hays fallback.
      rootStore.userToken = uid || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTcwMTc4ODE2MiwianRpIjoiMjBjNTNhODktZjliMy00NjI5LThiMzktNWZkYTJhM2ZiYjcxIiwidHlwZSI6ImFjY2VzcyIsInN1YiI6ImFkbWluQGhheXMuY28udWsiLCJuYmYiOjE3MDE3ODgxNjIsImV4cCI6MTczMzMyNDE2Miwicm9sZSI6ImNsaWVudCJ9.mdb3Q5LdxnQbnj0t9gu80Hsv8EaoMmmyHGYabovcRg8';
      rootStore.searchMode = Number(st) || -1;
      if(shwtb != null){
        if(shwtb == '0'){
          rootStore.showSearchTabs = false;
        }
        else{
          rootStore.showSearchTabs = true;
        }
      }

      if(agtusr != null){
        rootStore.agent = Number(agtusr);
      }

      if(aff != null){
        rootStore.affiliate = Number(aff);
      }

      clientConfiuration(rootStore.userToken).then((r) => {
        rootStore.configuration = r.configuration;
        root.render(
          <>
            <QueryClientProvider client={queryClient}>
              <Provider RootStore={rootStore}>
                <SearchProvider>
                  <RouterProvider router={widgetRouter} />
                </SearchProvider>
              </Provider>
            </QueryClientProvider>
          </>,
        );
      });


    } else {
      siteSign().then((r) => {

        rootStore.configuration = r.configuration;
        rootStore.userToken = r.token;

        if(r.configuration.gglid != null){
          install(r.configuration.gglid); 
        }

        gtag('js', new Date());
        gtag('config', r.configuration.gglid);

        const appleIco = document.createElement('LINK') as any;
        appleIco.rel = 'apple-touch-icon';
        appleIco.href = r.configuration.fav_icon.apple_touch;
        headSection?.appendChild(appleIco)

        const ico = document.createElement('LINK') as any;
        ico.rel = 'icon';
        ico.href = r.configuration.fav_icon.fav_l;
        headSection?.appendChild(ico)

        const mani = document.createElement('LINK') as any;
        mani.rel = 'manifest';
        mani.href = r.configuration.fav_icon.manifest;
        headSection?.appendChild(mani)

        const scfav = document.createElement('LINK') as any;
        scfav.rel = 'shortcut icon';
        scfav.href = r.configuration.fav_icon.apple_touch;
        headSection?.appendChild(scfav)
        
        document.title = r.configuration.document_title;

        const elementTags = document.getElementById('headSection') as any;
        if(r.configuration.scripts.length > 0){
          for(const t of r.configuration.scripts){
            const tagID = t.id;
            if(tagID == 'chathays'){
              continue
            }
            if(document.getElementById(tagID) != null){
              continue;
            }
            const tag = document.createElement('script');
            tag.id = tagID;
            for(const ta of t.attributes){
              tag.setAttribute(ta.name, ta.value)
            }
            if(t.src != ''){
              tag.src = t.src;
              elementTags.append(tag);
              // if script has a onload callback (GA does for example)
              if(t.onload != null){
                tag.onload = () => {
                  const subTag = document.createElement('script');
                  subTag.id = tagID + '_s';
                  subTag.innerText = t.onload;
                  elementTags.append(subTag);
                }
              }
            }
            else{
              tag.innerText = t.onload;
              elementTags.append(tag);
            }
          }
        }

        root.render(
          <>
            <QueryClientProvider client={queryClient}>
              <Provider RootStore={rootStore}>
                <SearchProvider>
                  <RouterProvider router={router} />
                </SearchProvider>
              </Provider>
            </QueryClientProvider>
          </>,
        );  
      })
    }


  // rootStore.userToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTY3MDI1MDUxMiwianRpIjoiMjk5ZDE5ZDQtNGE0OC00YTZjLWEyZmYtOGFlZjA5YzFhYTMxIiwidHlwZSI6ImFjY2VzcyIsInN1YiI6ImFkbWluQGhheXMuY28udWsiLCJuYmYiOjE2NzAyNTA1MTIsImV4cCI6MTcwMTc4NjUxMiwicm9sZSI6ImNsaWVudCJ9.pikaT_RmUlem-dev8RzWaNnpC21ir5saQrbKM5apM_k';


// })



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
