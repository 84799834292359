import { Fragment } from 'react';

export type SortSectionProps = {
  sortOptions: { label: string; value: string | number }[];
  onSortChange: (option: number) => void;
  activeOption: number;
};

export function SortSection({ sortOptions, onSortChange, activeOption }: SortSectionProps) {
  return (
    <div className='filter-wrapper'>
      <div className='filter-wrapper--title'>Sort</div>

      <div className='filter-wrapper--filters-wrapper'>
        {sortOptions &&
          sortOptions.map((item, key) => {
            const isChecked = item.value === activeOption;
            return (
              <Fragment key={`${key}-string`}>
                <div className={'filter-item'}>
                  <input
                    className={'filter-item--checkbox'}
                    type='checkbox'
                    onChange={(event) => onSortChange(parseInt(event.target.value))}
                    value={item.value}
                    checked={isChecked}
                  />
                  <span onClick={() => onSortChange(Number(item.value))} className={'filter-item--title'}>{item.label}</span>
                </div>
              </Fragment>
            );
          })}
      </div>
    </div>
  );
}
