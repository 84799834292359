import React, { FunctionComponent, useRef, useState, useEffect } from 'react';
import { SearchBarInputField } from '../SearchBarInputField';
import './SearchBar.scss';
import './DurationSelect.scss';

import { Modal } from '../Modal';
import { Option } from '../Autocomplete';
import { Button } from '../Button';

import moon from '../assets/moon.svg';
import nightsDefault from '../assets/icons/default/01/Nights.svg';

import Duration from '../Duration/Duration';
import { noop } from 'lodash';
import {
  availableDuration,
  packageAvailableDurations,
} from '../../hooks/useRequest';
import { useSearchProvider } from '../../context/search';

import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';

import { SearchSelections } from '../../hooks/types'

import { roomsToString } from '../../services/pax';

import { useOutsideClick } from '../../hooks/useOutsideClick';

interface DurationSelectProps {
  clickApplyHandler: (option: number, name: string) => void;
  clickCancelHandler?: () => void;
  onActivate?: () => void;
  resetItemType?: (type: string) => void;
  clickedSearchButtonItemName?: string;
  selectedSearchParams: SearchSelections;
  accommodationId?: number;
  showIcons: boolean;
  checkDate?: boolean;
}

interface Ops {
  value: number;
  label: string;
}

const DurationSelect: FunctionComponent<DurationSelectProps> = ({
  clickApplyHandler,
  clickCancelHandler,
  resetItemType = noop,
  clickedSearchButtonItemName,
  selectedSearchParams,
  accommodationId,
  checkDate = true,
  onActivate,
  showIcons = false,
}) => {

  const {
    RootStore: {
      userToken,
      configuration,
    },
  } = useStores() as { RootStore: RootStore };

  const { state } = useSearchProvider();

  let rooms = JSON.stringify(['2']);
  let duration = 7;
  let accID: any = null;
  const airports: string[] = [];
  const destinations: number[] = [];
  const regions: number[] = [];
  const resorts: number[] = [];
  const boards: number[] = [];
  const ratings: number[] = [];
  const tripRatings: number[] = [];

  if (selectedSearchParams != null) {
    if (selectedSearchParams.rooms != null) {
      rooms = roomsToString(selectedSearchParams.rooms);
    }

    if (selectedSearchParams.duration != null) {
      duration = selectedSearchParams.duration;
    }

    if (selectedSearchParams.boards != null) {
      for (const x of selectedSearchParams.boards) {
        boards.push(x);
      }
    }

    if (selectedSearchParams.departurePoints != null) {
      for (const x of selectedSearchParams.departurePoints) {
        airports.push(x);
      }
    }

    if (selectedSearchParams.destinations != null) {
      for (const x of selectedSearchParams.destinations) {
        destinations.push(x);
      }
    }

    if (selectedSearchParams.regions != null) {
      for (const x of selectedSearchParams.regions) {
        regions.push(x);
      }
    }

    if (selectedSearchParams.resorts != null) {
      for (const x of selectedSearchParams.resorts) {
        resorts.push(x);
      }
    }

    if (selectedSearchParams.ratings != null) {
      for (const x of selectedSearchParams.ratings) {
        ratings.push(x);
      }
    }

    if (selectedSearchParams.tripRatings != null) {
      for (const x of selectedSearchParams.tripRatings) {
        tripRatings.push(x);
      }
    }
  }

  const selectorRef = useRef<HTMLDivElement>(null);

  if (accommodationId != null) {
    accID = accommodationId;
  }

  let selectedDur = selectedSearchParams.duration;
  if (selectedDur == null) {
    selectedDur = duration;
  }
  const [durationOptions, setDurationOptions] = useState<Ops[]>([]);
  const [selectExpanded, setSelectExpanded] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<number>(selectedDur);

  useOutsideClick([selectorRef], () => {
    setSelectExpanded(false);
  })

  useEffect(() => {
    if (selectedSearchParams == null) {
      return;
    }

    setSelectedValue(selectedSearchParams.duration);
  }, [selectedSearchParams.duration]);

  useEffect(() => {

    const xEl = document.getElementById('cloudlink-chat-overlay-buttons');
    if(xEl != null){
      if(selectExpanded){
      
          if(xEl != null){
          xEl.style.display = 'none';
          }
      }
      else{
          if(xEl != null){
              xEl.style.display = 'block';
          }
      }
  }


    if (!selectExpanded) {
      return;
    }

    if (selectedSearchParams?.searchType == 2) {
      let rg = -1;
      let res = -1;

      if (resorts.length > 0) {
        res = resorts[0];
      } else {
        if (regions.length > 0) {
          rg = regions[0];
        }
      }

      packageAvailableDurations(userToken, regions, resorts, airports[0], selectedSearchParams.departureDate).then((d) => {
        let defaultDuration = 0;
        const durOps = (d as any).package_available_durations.result.map((i: any) => {
          if (state.option == 0) {
            if (i == 7) {
              defaultDuration = i;
            }
          } else {
            if (i == 3) {
              defaultDuration = i;
            }
          }

          const label = i === 1 ? 'Night' : 'Nights';
          return {
            value: i,
            label: `${i} ${label}`,
          };
        });
        setDurationOptions(durOps);

        if (selectedSearchParams.duration != null) {
          setSelectedValue(selectedSearchParams.duration);
        } else {
          if (defaultDuration == 0) {
            setSelectedValue(durOps[0].value);
          } else {
            setSelectedValue(defaultDuration);
          }
        }
      });

      return;
    }

    let dep = null;
    if(checkDate){
      dep = selectedSearchParams.departureDate;
    }


    availableDuration(
      userToken,
      rooms,
      airports,
      dep || null,
      selectedSearchParams.departureDateType,
      destinations,
      regions,
      resorts,
      boards,
      ratings,
      tripRatings,
      selectedSearchParams?.searchType || state.option,
      accID,
    ).then((d) => {
      let defaultDuration = 0;
      const durOps = (d as any).available_durations.result.map((i: any) => {
        if (state.option == 0) {
          if (i == 7) {
            defaultDuration = i;
          }
        } else {
          if (i == 3) {
            defaultDuration = i;
          }
        }

        const label = i === 1 ? 'Night' : 'Nights';
        return {
          value: i,
          label: `${i} ${label}`,
        };
      });
      setDurationOptions(durOps);

      if (selectedSearchParams?.duration != null) {
        setSelectedValue(selectedSearchParams.duration);
      } else {
        if (defaultDuration == 0) {
          setSelectedValue(durOps[0].value);
        } else {
          setSelectedValue(defaultDuration);
        }
      }
    });
  }, [selectExpanded]);

  useEffect(() => {
    if (clickedSearchButtonItemName === 'Duration' && !selectExpanded) {
      resetItemType('');
      setSelectExpanded(true);
    }
  }, [clickedSearchButtonItemName]);

  // useEffect(() => {
  //   clickApplyHandler(selectedValue, 'duration');
  // }, [selectedValue]);

  const searchBarInputFieldRef = useRef<HTMLDivElement>(null);

  const handleApplyValues = (option: number) => {
    setSelectedValue(option);
    clickApplyHandler(option, 'duration');
    setSelectExpanded(false);
  };

  let nightsIco = nightsDefault;
  if(configuration.icon_set == 'blue'){
    nightsIco = moon;
  }

  const icon = <img src={nightsIco} width='22' alt='moon' />;


  let bc = configuration.text_colour;
  let titleColour =  configuration.button_colour;
  let cancelText = configuration.button_colour;
  let primButtonColour = configuration.button_colour;
  if(configuration.button_icon == 'dark'){
    bc = configuration.border_colour_2;
    titleColour =  configuration.border_colour_2;
  }

  let selStyle = {backgroundColor: configuration.button_colour, borderColor:configuration.border_colour_2};

  if(configuration.spec == '6yk1y'){
    primButtonColour = configuration.button_colour;
    cancelText = '#01447b';
    bc = '#01447b';
    titleColour =  '#01447b';
    selStyle = {backgroundColor: '#01447b', borderColor:configuration.border_colour_2};
  }


  const modalHeader = (
  <div className='modal-mobile-header'>
    <h5 style={{color: bc}} className='modal-mobile-header-text'>Select Duration</h5>
  </div>
);

  const modalFooter = (
    <div className='modal-mobile-footer'>
      <Button
        hoverStyle={false} 
        borderColor={bc} 
        color={cancelText} 
        label='Cancel'
        isTransparent
        onClick={() => {
          setSelectExpanded(false);
        }}
        backgroundColor={configuration.button_colour}
      />
      <Button label='Apply' backgroundColor={primButtonColour} borderColor={primButtonColour} primary onClick={() => handleApplyValues(selectedValue)} />
    </div>
  );

  return (
    <>
      {selectExpanded && (
        <div
          className='duration-select-overlay'
          onClick={() => {
            setSelectExpanded(false);
          }}
        />
      )}


      <div ref={selectorRef} style={{width: '100%'}} >
      <div className='search-bar-input-field-autocomplete duration-wrapper'>
        <SearchBarInputField
          title='Duration'
          value={selectedValue + ' Nights'}
          focus={selectExpanded}
          configuration={configuration}
          onClick={() => {
            setSelectExpanded(!selectExpanded);
            if (onActivate) {
              onActivate();
            }
          }}
          icon={showIcons ? icon : null}
          ref={searchBarInputFieldRef}
        />

        {selectExpanded && (
          <div className='duration-select-desktop-view'>
            <Duration
              options={durationOptions}
              onChange={(option) => handleApplyValues(option.value)}
              selectedValues={[selectedValue]}
            />
          </div>
        )}
      </div>

      <div className='duration-select-mobile-view'>
        <Modal
          toggle={() => {
            setSelectExpanded(false);
          }}
          isOpen={selectExpanded}
          header={modalHeader}
          footer={null}
        >
          <Duration
            options={durationOptions}
            onChange={(option) => handleApplyValues(option.value)}
            selectedValues={[selectedValue]}
          />
        </Modal>
      </div>
      </div>
 
    </>
  );
};

export default DurationSelect;
