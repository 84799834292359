import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { BaseLayout } from '../../components/BaseLayout';
import { noop } from 'lodash';
import { FiltersSidebar } from '../../components/FiltersSidebar/FiltersSidebar';

import PackHolidayCard from '../../components/PackageHolidayCard/PackageHolidayCard';

import { PageLoader } from '../../components/PageLoader';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  packageAvailableOptions,
  packageSearch,
  packageFilter,
} from '../../hooks/useRequest';
import { SearchBar } from '../../components/SearchBar';

import useMultipleMedia from '../../hooks/useMultipleMedia';
import { useSearchProvider } from '../../context/search';
import { Package } from '../../hooks/types';
import { Button } from '../../components/Button';

import { SortBy } from '../../components/SortBy/SortBy';
import { LoadingModal } from '../../components/FiltersSidebar/LoadingModal';
import { LoadingOverlay } from '../../components/LoadingOverlay/LoadingOverlay';
import { LoaderSpinner } from '../../components/Loader/Loader';

import { RootStore } from '../../stores/RootStore';
import { useStores } from '../../hooks/useStore';

import './PackageSearchResultsPage.scss';

// Images
import AtolIcon from '../../components/assets/atol.svg';
import AtbaIcon from '../../components/assets/atba.svg';

import atolIconDefault from '../../components/assets/icons/default/01/TOL.svg';
import abtaIconDefault from '../../components/assets/icons/default/01/ABTA.svg';


import { useModal } from '../../hooks/useModal';

import { fbqTrackSearch } from '../../hooks/fb';

import { getParamsString, getDestinationParamsString, getRegionParamsString, getResortParamsString, getBoardParamsString, 
  getRatingParamsString, getTripRatingParamsString } from '../../services/search';
import { clearSessionKeys } from '../../services/session';
import { roomsFromPaxString } from '../../services/pax';


export default function PackageSearchResultsPage() {
  const {
    RootStore: {
      userToken,
      configuration,
    },
  } = useStores() as { RootStore: RootStore };

  const snap = sessionStorage.getItem('snpOff');

  let defaultOffers: any[] = [];
  let defaultPage = 20;
  let defaultSort = 0;
  let defaultCount = 0;
  let defaultOfferID = '';
  let defaultBoards: any[] = [];
  let defaultRatings: any[] = [];
  let defaultTripRatings: any[] = [];
  let defaultResorts: any[] = [];

  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('session');

  if (snap != null) {
    const snapO = JSON.parse(snap);
    if(snapO.localSession == sessionId){
      defaultOffers = snapO.offers;
      defaultPage = snapO.startIndex;
      defaultSort = snapO.sortIndex;
      defaultCount = snapO.cnt;
      defaultOfferID = snapO.selectedOffer;

      defaultBoards = snapO.availBoards;
      defaultRatings = snapO.availRatings;
      defaultTripRatings = snapO.availTripRatings;
      defaultResorts = snapO.availResorts;
    }
    else{
      sessionStorage.removeItem('snpOff');
    }
  }

  const navigate = useNavigate();

  const [defaultsDone, setDefaultsDone] = useState<boolean>(false);

  const [isOpen, toggle] = useModal(false);

  const isMobile = useMultipleMedia(['(max-width: 768px)'], [true], false);
  const [isSearchBarOpen, setIsSearchBarOpen] = useState<boolean>(isMobile ? true : false);
  const [searchItemType, setSearchItemType] = useState<string>();
  const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>(false);
  const [isMapViewActive, setIsMapViewActive] = useState<boolean>(false);

  // const [actualParams, setActualParams] = useState<any>(parsedSearchParams);
  const [availResorts, setAvailableResorts] = useState<any>(defaultResorts);
  const [availBoards, setAvailableBoards] = useState<any>(defaultBoards);
  const [availRatings, setAvailableRatings] = useState<any>(defaultRatings);
  const [availTripRatings, setAvailableTripRatings] = useState<any>(defaultTripRatings);

  const [iniLoad, setIniLoad] = useState<boolean>(false);
  const [smallLoader, setMiniLoad] = useState<boolean>(false);

  const [offersData, setOfferData] = useState<Package[]>([]);
  const [offersFetching, setOfferFetch] = useState<boolean>(true);
  const [offerId, setOfferId] = useState<any>();
  const [selectedOffer, setSelectedOffer] = useState<{
    id: string;
    totalPrice: number;
    offerPricePerPerson: number;
  } | null>();

  const [startIndex, setStartIndex] = useState<number>(defaultPage);
  const [isTotalPrice, setIsTotalPrice] = useState(false);
  const [sortIndex, setSortIndex] = useState<number>(0);
  const [offerCount, setOfferCount] = useState<number>(0);
  const [noResort, setNoResort] = useState<boolean>(false);



  let selDests : number[] = [];
  let selRegions : number[] = [];
  let selResorts : number[] = [];

  if(sessionId == null){
    return null;
  }

  const qsDests = searchParams.get('destinations');
  if(qsDests != null){
    for(const x of qsDests.split('-')){
        selDests.push(Number(x))
    }
  }
  const qsRegions = searchParams.get('regions');
  if(qsRegions != null){
    for(const x of qsRegions.split('-')){
        selRegions.push(Number(x))
    }
  }
  const qsResorts = searchParams.get('resorts');
  if(qsResorts != null){
    for(const x of qsResorts.split('-')){
        selResorts.push(Number(x))
    }
  }

  let departureDate: any = null;
  const qsDeparting = searchParams.get('departing');
  if(qsDeparting != null){
    departureDate = qsDeparting + ' 00:00';
  }

  let selDeparturePoints: string[] = [];
  const qsDepPoints = searchParams.get('departurePoints');
  if(qsDepPoints != null){
    for(const x of qsDepPoints.split('-')){
        selDeparturePoints.push(x)
    }
  }

  let duration = 7;
  if(searchParams.get('duration') != null){
    duration = Number(searchParams.get('duration'));
  }

  let selBoards: number[] = [];
  const qsBaords = searchParams.get('boards');
  if(qsBaords != null){
    for(const x of qsBaords.split('-')){
      selBoards.push(Number(x))
    }
  }

  let selRatings: number[] = [];
  const qsRates = searchParams.get('ratings');
  if(qsRates != null){
    for(const x of qsRates.split('-')){
      selRatings.push(Number(x))
    }
  }  
  
  let selTripRatings: number[] = [];
  const qsTrRates = searchParams.get('tratings');
  if(qsTrRates != null){
    for(const x of qsTrRates.split('-')){
      selTripRatings.push(Number(x))
    }
  }

  let rooms = searchParams.get('rooms') || '2-';

  const sortOptions = [
    {
      label: 'Lowest Price',
      value: 0,
    },
    {
      label: 'Highest Price',
      value: 1,
    },
    {
      label: 'Star Rating',
      value: 3,
    },
    {
      label: 'Tripadvisor Rating',
      value: 5,
    },
  ];

  // const searchChange = (value: any) => {
  //   setActualParams(value);
  // };

  // const handleSearchChange = () => {
  //   if (userSession.sessionId == null) {
  //     return;
  //   }

  //   if (!iniLoad) {
  //     return;
  //   }

  //   const persons = roomsFromPaxString(
  //     actualParams.rooms || [
  //       {
  //         adults: 2,
  //         children: 0,
  //       },
  //     ],
  //   );

  //   const airports = getAirportsFromParams(actualParams.airports);
  //   const duration = getDurationFromParams(actualParams);
  //   const departureDate = getDepartureDateFromParams(actualParams, 'value');
  //   const destinations = getDestinationsFromParams(actualParams, 'destinations');
  //   const resorts = getDestinationsFromParams(actualParams, 'resort');
  //   const regions = getDestinationsFromParams(actualParams, 'region');

  //   if (airports.length === 0) {
  //     return { type: 'error', key: 'airports', value: 'Please select an airport' };
  //   }

  //   if (regions.length === 0 && resorts.length === 0) {
  //     return { type: 'error', key: 'destinations', value: 'Please select destination' };
  //   }

  //   if (!departureDate) {
  //     return { type: 'error', key: 'departure_date', value: 'Please select a date' };
  //   }

  //   sessionStorage.setItem('aprm', JSON.stringify(actualParams));

  //   let rg = -1;
  //   let res = -1;

  //   if (resorts.length > 0) {
  //     res = resorts[0];
  //   } else {
  //     if (regions.length > 0) {
  //       rg = regions[0];
  //     }
  //   }

  //   setMiniLoad(true);
  //   packageSearch(
  //     userToken,
  //     userSession.sessionId,
  //     JSON.stringify(persons),
  //     airports[0],
  //     departureDate,
  //     duration,
  //     sortIndex,
  //     regions,
  //     resorts
  //   ).then((pdr: any) => {
  //     if (pdr == null) {
  //       return;
  //     }
  //     if (pdr.package_search.result == null) {
  //       return;
  //     }
  //     if (pdr.package_search.result.lengh == 0) {
  //       return;
  //     }

  //     packageAvailableOptions(userToken, userSession.sessionId || '', [], [], [], []).then((d: any) => {
  //       setAvailableBoards(d.package_available_options.result.boards);
  //       setAvailableResorts(d.package_available_options.result.resorts);
  //       setAvailableRatings(d.package_available_options.result.ratings);
  //       setAvailableTripRatings(d.package_available_options.result.trip_ratings);

  //       setMiniLoad(false);
  //       setIniLoad(true);

  //       setStartIndex(20);

  //       if(!isMobile){
  //         setIsSearchBarOpen(false);
  //       }else{
  //         // always open on mobile.
  //         setIsSearchBarOpen(true);
  //       }
      

  //       setSelectedBoards([]);
  //       setSelectedRatings([]);
  //       setSelectedTripRatings([]);
  //       setDefaultsDone(true);

  //       setOfferData(pdr.package_search.result);
  //       setOfferCount(pdr.package_search.result.length);
  //       setOfferFetch(false);
  //     });
  //   });
  // };


  const boardChange = (board: number) => {
    const strParams = getParamsString(searchParams)

    const strDest = getDestinationParamsString(searchParams);
    const strReg = getRegionParamsString(searchParams);
    const strRes = getResortParamsString(searchParams);

    const strRates = getRatingParamsString(searchParams);
    const strTripRates = getTripRatingParamsString(searchParams);

    const index = selBoards.indexOf(board);
    if(index != -1){
      selBoards.splice(index, 1);
    }
    else{
      selBoards.push(board);
    }
    let strBoards = '';
    if(selBoards.length > 0){
      strBoards = '&boards=' + selBoards.join('-');
    }
    navigate(`/package-search?${strParams + strDest + strReg + strRes + strBoards + strRates + strTripRates + '&session=' + sessionId + '&filter=1'}`, {replace: true});
  }
  const ratingChange = (r: number) => {
    const strParams = getParamsString(searchParams);

    const strDest = getDestinationParamsString(searchParams);
    const strReg = getRegionParamsString(searchParams);
    const strRes = getResortParamsString(searchParams);


    const strBoards = getBoardParamsString(searchParams);
    const strTripRates = getTripRatingParamsString(searchParams);

    const index = selRatings.indexOf(r);
    if(index != -1){
      selRatings.splice(index, 1);
    }
    else{
      selRatings.push(r);
    }
    let strRates = '';
    if(selRatings.length > 0){
      strRates = '&ratings=' + selRatings.join('-');
    }
    navigate(`/package-search?${strParams + strDest + strReg + strRes + strBoards + strRates + strTripRates + '&session=' + sessionId + '&filter=1'}`, {replace: true});
  }

  const tripRatingChange = (tr: number) => {
    const strParams = getParamsString(searchParams);

    const strDest = getDestinationParamsString(searchParams);
    const strReg = getRegionParamsString(searchParams);
    const strRes = getResortParamsString(searchParams);
    
    const strBoards = getBoardParamsString(searchParams);
    const strRates = getRatingParamsString(searchParams);

    const index = selTripRatings.indexOf(tr);
    if(index != -1){
      selTripRatings.splice(index, 1);
    }
    else{
      selTripRatings.push(tr);
    }
    let strTripRates = '';
    if(selTripRatings.length > 0){
      strTripRates = '&tratings=' + selTripRatings.join('-');
    }
    navigate(`/package-search?${strParams + strDest + strReg + strRes + strBoards + strRates + strTripRates + '&session=' + sessionId + '&filter=1'}`, {replace: true});
  }

  const resortChange = (d: number) => {
    const strParams = getParamsString(searchParams)

    const strDest = getDestinationParamsString(searchParams);
    const strReg = getRegionParamsString(searchParams);
    
    const strBoards = getBoardParamsString(searchParams);
    const strRates = getRatingParamsString(searchParams);
    const strTripRates = getTripRatingParamsString(searchParams);

    const index = selResorts.indexOf(d);
    if(index != -1){
      selResorts.splice(index, 1);
    }
    else{
      selResorts.push(d);
    }
    let strRes = '';
    if(selResorts.length > 0){
      strRes = '&resorts=' + selResorts.join('-');
    }
    navigate(`/package-search?${strParams + strDest + strReg + strRes + strBoards + strRates + strTripRates + '&session=' + sessionId + '&filter=1'}`, {replace: true});
  }

  const setSort = (sortIndex: number) => {
    if(sortIndex == 6){
      setSortIndex(sortIndex);
      return
    }
    setSortIndex(sortIndex)
  }

  const snapOffers = (id: string) => {
    // const [availBoards, setAvailableBoards] = useState<any>([]);
    // const [availRatings, setAvailableRatings] = useState<any>([]);
    // const [availTripRatings, setAvailableTripRatings] = useState<any>([]);

    const x = {
      offers: offersData,
      selectedOffer: id,
      startIndex: startIndex,
      sortIndex: sortIndex,
      cnt: offerCount,
      availBoards: availBoards,
      availRatings: availRatings,
      availTripRatings: availTripRatings,
      availResorts: availResorts,
      localSession: sessionId,
    };
    // const xx = JSON.stringify(x);
    sessionStorage.setItem('snpOff', JSON.stringify(x));
  };

  const homeClick = () => {
    clearSessionKeys(['snpOff', 'f', 'aprm', 'js1', 'js2', 'js3', 'js4', 'js5', 'shosnt', 'gap']);
    location.href = configuration.home_url
  }

  function toggleFilterModal() {
    setIsFiltersOpen(!isFiltersOpen);
  }

  function handleNextPage() {
    if (!iniLoad) {
      return;
    }
    // setMiniLoad(true);
    setStartIndex(startIndex + 20);
    // do something with the data.
  }

  useEffect(() => {
    sessionStorage.removeItem('session_cr');

    const clearPrevSearch = searchParams.get('clrPrvSrch');
    if(clearPrevSearch != null){
        if(clearPrevSearch == '1'){
          localStorage.removeItem('prevsrch');
        }
    }

  }, [])
  
  useEffect(() => {

    async function ini() {


      if (!iniLoad && defaultOffers.length > 0) {

        setAvailableBoards(defaultBoards);
        setAvailableRatings(defaultRatings);
        setAvailableTripRatings(defaultTripRatings);
        setAvailableResorts(defaultResorts);

        setOfferData(defaultOffers);
        setOfferFetch(false);
        setOfferCount(defaultOffers.length);
        setIniLoad(true);

      }
      else{

        if(sessionId == null){
          return null;
        }

        
        setMiniLoad(true);
        const psResult = await packageSearch(userToken, sessionId, rooms, selDeparturePoints[0], 
          departureDate, duration, sortIndex, selRegions, selResorts);
  
        if (psResult == null) {
          return;
        }
        if (psResult.package_search.result == null) {
          return;
        }
        if (psResult.package_search.result.length == 0) {
          return;
        }
  
        const d = await packageAvailableOptions(userToken, sessionId, selBoards, selRatings, selTripRatings, selResorts)
        setAvailableResorts(d.package_available_options.result.resorts);
        setAvailableBoards(d.package_available_options.result.boards);
        setAvailableRatings(d.package_available_options.result.ratings);
        setAvailableTripRatings(d.package_available_options.result.trip_ratings);
  
        setOfferData(psResult.package_search.result);
        setOfferCount(psResult.package_search.result.length);
        setMiniLoad(false);
        setIniLoad(true);
        setOfferFetch(false);
  
        fbq(psResult.package_search.result)
      }


    }

    async function ini_filter() {

      if(sessionId == null){
        return null;
      }

      if (!iniLoad && defaultOffers.length > 0) {

        setAvailableBoards(defaultBoards);
        setAvailableRatings(defaultRatings);
        setAvailableTripRatings(defaultTripRatings);
        setAvailableResorts(defaultResorts);

        setOfferData(defaultOffers);
        setOfferFetch(false);
        setOfferCount(defaultOffers.length);
        setIniLoad(true);

      }
      else{
        setMiniLoad(true);

        const psResult = await packageFilter(userToken, sessionId, sortIndex, selBoards, selRatings, selTripRatings, selResorts);
  
        if (psResult == null) {
          return;
        }
        if (psResult.package_filter.result == null) {
          return;
        }
        if (psResult.package_filter.result.length == 0) {
          return;
        }
  
        const d = await packageAvailableOptions(userToken, sessionId, selBoards, selRatings, selTripRatings, selResorts)
        setAvailableResorts(d.package_available_options.result.resorts);
        setAvailableBoards(d.package_available_options.result.boards);
        setAvailableRatings(d.package_available_options.result.ratings);
        setAvailableTripRatings(d.package_available_options.result.trip_ratings);
  
        setOfferData(psResult.package_filter.result);
        setOfferCount(psResult.package_filter.result.length);
        setMiniLoad(false);
        setOfferFetch(false);
      }

    }


    if(searchParams.get('filter')){
      ini_filter();
    }
    else{
      ini();
    }

    

  }, [searchParams]);
  
  
  useEffect(() => {

    if (!iniLoad) {
      return;
    }

    async function ini_sorrt() {

      if(sessionId == null){
        return null;
      }
    

      setMiniLoad(true);
      const psResult = await packageFilter(userToken, sessionId, sortIndex, selBoards, selRatings, selTripRatings, selResorts);

      if (psResult == null) {
        return;
      }
      if (psResult.package_filter.result == null) {
        return;
      }
      if (psResult.package_filter.result.length == 0) {
        return;
      }
    
      setOfferData(psResult.package_filter.result);
      setOfferCount(psResult.package_filter.result.length);
      setMiniLoad(false);
      setIniLoad(true);
      setOfferFetch(false);
    }
    ini_sorrt();
  }, [sortIndex]);




  const fbq = (offers : Package[]) => {
    if(offers == null){
      return
    }

    if(offers.length == 0){
      return
    }


    let c = 1;
    const contentIDS = []
    for(const o of offers){
      if(c > 19){
        break
      }
      contentIDS.push("'" + o.id + "'");
    }

    const strCIDS = '[' + contentIDS.join(',') + ']';


    const o = offers[0]
    const cin = dayjs(o.flight.outbound_arrival).format('YYYY-MM-DD');
    const cout = dayjs(o.flight.inbound_departure).format('YYYY-MM-DD');
    
    fbqTrackSearch(o.accommodation.resort.name, o.accommodation.resort.regions[0].name, o.accommodation.resort.regions[0].destinations[0].name, 
      cin, cout, strCIDS , o.rooms[0].adults, o.rooms[0].children)
  }

  useEffect(() => {
    if (defaultsDone) {
      return;
    }

    window.location.href = '#' + defaultOfferID;
  }, [offersData]);

  let xOverlayLoad = null;

  if (smallLoader) {
    xOverlayLoad = <LoadingOverlay ringColour={configuration.button_colour} buttonText={configuration.button_text_colour}></LoadingOverlay>;
  }

  const onMapLinkClickHandler = ({
    id,
    offerPrice,
    offerPricePerPerson,
  }: {
    id: string;
    offerPrice: number;
    offerPricePerPerson: number;
  }) => {
    setOfferId(id);

    // Get the selected offer
    setSelectedOffer({ id, totalPrice: offerPrice, offerPricePerPerson });

    toggle();
  };

  if (!offersFetching) {
    if (offerCount == 0) {
      return (
        <div className='pak-search-results-page'>
          <PageLoader config={configuration} />

          <div className='package-search-loader-overlay'>
            <div className='package-search-loader-modal'>
              <p className='package-search-loader-modal__title'>Sorry about this...</p>

              {/* <div className='package-search-loader-modal__spinner'>
                  <LoaderSpinner />
                </div> */}

              <p>
                Unfortunately we could not find any results for your search.
                <br></br>
                <a href='#' onClick={homeClick}>Try a new search</a>
              </p>

              <div className='package-search-loader-modal__logos'>
                <img width={150} src={AtbaIcon} />
                <img width={50} src={AtolIcon} />
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  let noResortCon = null;
  if (noResort) {
    noResortCon = (
      <div>
        <p>
          Sorry we could not find the exact place you wanted, we have found some alternatives
          however. <br></br>
          Alternatively why not try our Beach Holiday search and build your own fully financial
          protected holiday where we can offer you a lot more choice. <br></br>
        </p>
      </div>
    );
  }

  let atolIco = atolIconDefault;
  let abtaIco = abtaIconDefault;
  if(configuration.icon_set == 'blue'){
    atolIco = AtolIcon;
    abtaIco = AtbaIcon;
  }

  // let mapSec = null;
  // if(mapOffer && isOpen){
  //   mapSec = <MapModal
  //   offer={mapOffer}
  //   isOpen={isOpen}
  //   toggle={toggle}
  //   searchParams={searchParams}
  //   onTotalPriceChange={(e: any) => setIsTotalPrice(e)}
  //   isTotalPriceFlag={isTotalPrice}
  // />
  // }

  return (
    <BaseLayout
      isOverlayOpen={isSearchBarOpen}
      onSearchItemClick={setSearchItemType}
      searchParams={searchParams}
      onSearchButtonClick={() => {
        setIsSearchBarOpen(!isSearchBarOpen);
      }}
      userToken={userToken}
      userConfiguration={configuration}
    >
      <div className='pak-search-results-page'>
        {offersFetching ? (
          <>
            <PageLoader config={configuration} />

            <div className='package-search-loader-overlay'>
              <div className='package-search-loader-modal'>
                <p className='package-search-loader-modal__title'>Finding your holiday...</p>

                <div className='package-search-loader-modal__spinner'>
                  <LoaderSpinner />
                </div>

                <p>Loading, this may take up to 30 seconds.</p>

                <div className='package-search-loader-modal__logos'>
                  <img width={150} src={abtaIco} />
                  <img width={50} src={atolIco} />
                </div>
              </div>
            </div>

            {isFiltersOpen && <LoadingModal isModalOpen />}
            {isMapViewActive && (
              <div style={{ flex: 1 }}>
                <LoadingOverlay background='#fff' />
              </div>
            )}
          </>
        ) : (
          <>
            {xOverlayLoad}

            {noResortCon}

            <div className={isSearchBarOpen ? 'search-bar-wrapper' : 'hidden'}>
              <SearchBar
                searchItemType={searchItemType}
                isBoardType={false}
                isSearchPage={true}
                resultFilters={null}     
                onClick={() => {
                  setIsSearchBarOpen(!isSearchBarOpen);
                }}
              />
            </div>

            <div className='search-bar-wrapper search-bar-wrapper--no-index'>
              <div className='search-page-top-wrapper'>
                <h1 className='search-page-main-heading'>{offerCount} Holidays Found</h1>

                <div className='search-page-top-wrapper__btn-wrap'>
                  <SortBy
                    title='Sort by'
                    variants={sortOptions}
                    defaultValue={sortIndex}
                    callback={(index) => {
                      setSort(index);
                    }}
                  />
                </div>
              </div>

              <div className={'search-results-container'}>
                <FiltersSidebar
                  onTotalPriceChange={(e: any) => setIsTotalPrice(e)}
                  isModalOpen={isFiltersOpen}
                  toggleModal={toggleFilterModal}
                  isTotalPriceFlag={isTotalPrice}
                  onBoardChange={(b) => boardChange(b)}
                  onRatingChange={(b) => ratingChange(b)}
                  onTripRatingChange={(b) => tripRatingChange(b)} 
                  onDestChange={(b) => {return}}
                  onRegionChange={(b) => {return}}
                  onResortChange={(b) => resortChange(b)}
                  sortOptions={sortOptions}
                  onSortChange={(index: number) => setSort(index)}
                  activeOption={sortIndex}
                  boards={availBoards}
                  ratings={availRatings}
                  pakResorts={availResorts}
                  tripRatings={availTripRatings}
                  selectedBoards={selBoards}
                  selectedRatings={selRatings}
                  selectedTripRatings={selTripRatings}
                  selectedResorts={selResorts}
                  selectedRegions={[]}
                  selectedDestinations={[]}
                />

                {!isMapViewActive && (
                  <div style={{backgroundColor: configuration.container_colour}} className='filter-sidebar-button' role='button' onClick={toggleFilterModal}>
                    <p>Sort &amp; Filter</p>
                    <svg
                      width='1rem'
                      height='1rem'
                      version='1.1'
                      viewBox='0 0 1200 1200'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g fill='#fff'>
                        <path d='m1096 304.25c0 39.898-32.34 72.254-72.254 72.254h-847.48c-39.898 0-72.254-32.34-72.254-72.254 0-39.898 32.34-72.254 72.254-72.254h847.5c39.898 0.003906 72.238 32.355 72.238 72.254z' />
                        <path d='m976 607.88c0 39.898-32.34 72.254-72.254 72.254h-607.48c-39.898 0-72.254-32.34-72.254-72.254 0-39.898 32.34-72.254 72.254-72.254h607.5c39.898 0.003906 72.238 32.355 72.238 72.254z' />
                        <path d='m796 911.51c0 39.898-32.34 72.254-72.254 72.254h-247.48c-39.898 0-72.254-32.34-72.254-72.254 0-39.898 32.34-72.254 72.254-72.254h247.5c39.898 0.003906 72.238 32.355 72.238 72.254z' />
                      </g>
                    </svg>
                  </div>
                )}

                <div className={'holiday-card-container'}>
                  {

                    offersData.map((offer: any, i: number) => {

                      if(i > startIndex){
                        return
                      }

                      const matchedRooms = [];

                      for (const rm of offer.rooms) {
                        if (selBoards.length == 0) {
                          matchedRooms.push(rm);
                        } else {
                          for (const sb of selBoards) {
                            if (sb == rm.board) {
                              matchedRooms.push(rm);
                              break;
                            }
                          }
                        }
                      }

                      let offerPrice = 0;
                      let offerPricePerPerson = 0;
                      const selRooms = [];
         

                      let adlts = 0;
                      let child = 0;
                      const addedRooms = [];
                      let roomNumber = 1;
                      let bid = -1;

                      let roomObjs = roomsFromPaxString(rooms);
                      for (const sr of roomObjs) {
                        let chlds = 0;
                        let infs = 0;

                        for (const ca of sr.childAges) {
                          const cAge = Number(ca);
                          if (cAge > 1) {
                            chlds += 1;
                          } else {
                            infs += 1;
                          }
                        }

                       
                        for (const rm of matchedRooms) {
                          if (addedRooms.indexOf(rm.id) != -1) {
                            continue;
                          }

                          if(bid != -1 && rm.board != bid){
                            continue;
                          }

                          let roomInfs = 0;
                          if (rm.infants != null) {
                            roomInfs = rm.infants;
                          }
                          if (
                            rm.adults == sr.adults &&
                            rm.children == chlds &&
                            roomInfs == infs &&
                            rm.room_number == roomNumber
                          ) {
                            offerPrice += rm.price;
                            selRooms.push(rm);
                            adlts += rm.adults;
                            child += rm.children;
                            addedRooms.push(rm.id);
                            bid = rm.board;
                            break;
                          }
                        }
                        roomNumber++;
                      }

                      offerPricePerPerson += offerPrice / (adlts + child);

                      return (
                        <PackHolidayCard
                          sessionId={sessionId}
                          isMap={isMapViewActive}
                          packageOffer={offer}
                          onMapLinkClick={() =>
                            onMapLinkClickHandler({
                              id: offer.id,
                              offerPrice,
                              offerPricePerPerson,
                            })
                          }
                          isTotalPriceFlag={isTotalPrice}
                          price={offerPrice}
                          selectedRooms={selRooms}
                          pricePerPerson={offerPricePerPerson}
                          key={`${offer.id}-${i}`}
                          id={offer.id}
                          searchParams={searchParams}
                          onOfferClick={(id: any) => snapOffers(id)}
                        />
                      );
                    })}

                  {offerCount && offersData.length !== 0 && (startIndex + 1) < offerCount && (
                    <Button onClick={handleNextPage} label='Load More' size='large' borderColor={configuration.button_colour_tertiary} backgroundColor={configuration.button_colour_tertiary} color={configuration.button_text_tertiary_colour}  />
                  )}
                </div>
              </div>
            </div>

            {/* <MapModal
              offers={offersData}
              isOpen={isOpen}
              offerId={selectedOffer?.id || ''}
              toggle={toggle}
              searchParams={searchParams}
              onTotalPriceChange={(e: any) => setIsTotalPrice(e)}
              isTotalPriceFlag={isTotalPrice}
            /> */}

            {isMapViewActive && (
              <>
                <div className='map-view'>
                  <FiltersSidebar
                    onTotalPriceChange={(e: any) => setIsTotalPrice(e)}
                    isModalOpen={isFiltersOpen}
                    toggleModal={toggleFilterModal}
                    isTotalPriceFlag={isTotalPrice}
                    onBoardChange={(b) => boardChange(b)}
                    onRatingChange={(b) => ratingChange(b)}
                    onTripRatingChange={(b) => tripRatingChange(b)} 
                    onDestChange={(b) => {return}}
                    onRegionChange={(b) => {return}}
                    onResortChange={(b) => resortChange(b)}
                    sortOptions={sortOptions}
                    onSortChange={(index: number) => setSort(index)}
                    activeOption={sortIndex}
                    boards={availBoards}
                    ratings={availRatings}
                    pakResorts={availResorts}
                    tripRatings={availTripRatings}
                    selectedBoards={selBoards}
                    selectedRatings={selRatings}
                    selectedTripRatings={selTripRatings}
                    selectedResorts={selResorts}
                    selectedRegions={[]}
                    selectedDestinations={[]}
                  />

                  {/* <div style={{ flex: 1 }}>
                    <MapView
                      onClose={() => setIsMapViewActive(!isMapViewActive)}
                      offers={offersData}
                      isTotalPriceFlag={isTotalPrice}
                      selectedBoards={selectedBoards}
                      searchParams={parsedSearchParams || null}
                      localSession={localSession}
                      onOfferClick={(id) => snapOffers(id)}
                    />
                  </div> */}

                  <div style={{backgroundColor: configuration.container_colour}} className='filter-sidebar-button' role='button' onClick={toggleFilterModal}>
                    <p>Sort &amp; Filter</p>
                    <svg
                      width='1rem'
                      height='1rem'
                      version='1.1'
                      viewBox='0 0 1200 1200'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g fill='#fff'>
                        <path d='m1096 304.25c0 39.898-32.34 72.254-72.254 72.254h-847.48c-39.898 0-72.254-32.34-72.254-72.254 0-39.898 32.34-72.254 72.254-72.254h847.5c39.898 0.003906 72.238 32.355 72.238 72.254z' />
                        <path d='m976 607.88c0 39.898-32.34 72.254-72.254 72.254h-607.48c-39.898 0-72.254-32.34-72.254-72.254 0-39.898 32.34-72.254 72.254-72.254h607.5c39.898 0.003906 72.238 32.355 72.238 72.254z' />
                        <path d='m796 911.51c0 39.898-32.34 72.254-72.254 72.254h-247.48c-39.898 0-72.254-32.34-72.254-72.254 0-39.898 32.34-72.254 72.254-72.254h247.5c39.898 0.003906 72.238 32.355 72.238 72.254z' />
                      </g>
                    </svg>
                  </div>
                </div>

                <div className='map-view-overlay' />
              </>
            )}
          </>
        )}
      </div>
    </BaseLayout>
  );
}
