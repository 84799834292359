import dayjs from 'dayjs';
import { FunctionComponent, useEffect, useState } from 'react';
import { BaseLayout } from '../../components/BaseLayout';
import { noop } from 'lodash';
import './SelectPage.scss';
import { SelectFlights } from '../../components/SelectFlights';
import food from '../../components/assets/food.svg';
import bigPlane from '../../components/assets/plane-big.svg';
import {
  addProduct,
  productFlights,
  productRooms,
  sessionSave,
  paymentPayMonthly,
  journeyAdd
} from '../../hooks/useRequest';

import { Basket } from '../../components/Basket/Basket';
import { Loader } from '../../components/Loader/Loader';
import { RootStore } from '../../stores/RootStore';
import { useStores } from '../../hooks/useStore';
import { observer } from 'mobx-react';
import { Button } from '../../components/Button';

import { useNavigate, useSearchParams } from 'react-router-dom';

import { SelectBoard } from '../../components/SelectBoard';
import { Board } from '../../components/SelectBoard/SelectBoard';
import { ProgressBarDefault } from '../../components/ProgressBar/ProgressBarDefaults';
import { RoomGroup } from '../../hooks/types';
import { AccommodationSelection } from '../../components/AccommodationSelection';
import { useSearchProvider } from '../../context/search';

import { boardFromID, accommodation as getAccommodation } from '../../services/acc';
import { adultCountFromRooms, childCountFromRooms, roomsToStringURL, childAgesFromRooms } from '../../services/pax';
import { sessionGetSearchParams } from '../../services/search';
import { getBasketDP } from '../../services/basket';

import { Flight, Room, Basket as BasketData, Accommodation, Transfer} from '../../hooks/types';

interface HomePageProps {
  onLogin?: () => void;
  openMenu?: () => void;
}

const SelectPage: FunctionComponent<HomePageProps> = ({ onLogin = noop, openMenu = noop }) => {
  const {
    RootStore: {
      userToken,
      configuration,
    },
  } = useStores() as { RootStore: RootStore };

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const s = searchParams.get('session');

  const selectedSearchParams = sessionGetSearchParams();

  if(selectedSearchParams == null){
    return null;
  }

  const [isSearchBarOpen, setIsSearchBarOpen] = useState<boolean>(true);
  const [flightData, setFlightData] = useState<Flight[]>([]);

  const [sessionId] = useState<string>(s || '');

  const [boardDetails, setBoardDetails] = useState<Board[]>([]);
  const [roomData, setRoomData] = useState<RoomGroup[]>([]);
  const [roomDetailsFiltered, setRoomDetailsFiltered] = useState<RoomGroup[]>([]);

  const [roomLoaded, setRoomLoaded] = useState<boolean>(false);
  const [flightLoaded, setFlightLoaded] = useState<boolean>(false);
  const [everythingLoaded, setEverythingLoaded] = useState<boolean>(false);

  let desiredBoard = -1;
  if(sessionStorage.getItem('selectedBoard')){
    desiredBoard = Number(sessionStorage.getItem('selectedBoard'));
  }

  const [selectedBoardId, setSelectedBoardId] = useState<number>(desiredBoard);

  const [pricePlan , setPaymentPlan] = useState<any>(null);

  const [basket, setBasket] = useState<BasketData>();
  const [basketRooms, setBasketRooms] = useState<Room[]>();
  const [basketFlight, setBasketFlight] = useState<Flight>();
  const [basketTransfer, setBasketTransfer] = useState<Transfer | null>(null);

  const [accommodation, setAccommodation] = useState<Accommodation>();

  const [roomDisabled, setRoomDisabled] = useState<boolean>(false);
  const [boardDisabled, setBoardDisabled] = useState<boolean>(false);


  const { state } = useSearchProvider();


  let logoSrc = 'https://booking.haystravel.co.uk/static/media/hays_logo.svg'
  if(configuration != null){
    logoSrc = configuration.logo_file;
  }
  let fadeOut = false;
  const loadingScreen = () => {
    return (
      <div className={`loader-page-container ${everythingLoaded ? 'loader-page-fadeout' : ''}`}>
        <img className='loader-page-logo' src={logoSrc} height={100} />
        <Loader
          loading={{
            flights: flightLoaded,
            extras: roomLoaded,
            rooms: roomLoaded,
          }}
        />
      </div>
    );
  }

  const filterBoard = async (board: Board) => {

    if(boardDisabled){
      return;
    }

    setBoardDisabled(true);
    setRoomDisabled(true);
    if(basketRooms == null){
      return;
    }

    if (selectedBoardId == board.id) {
      return;
    }
    if (sessionId) {
      const availableRooms = roomData.map((item) => {
        return {
          ...item,
          rooms: item.rooms.filter((room) => room.board === board.id),
        };
      });


      const newRooms: Room[] = [];
      for (const sr of basketRooms) {
        for (const x of availableRooms) {
          if (x.rooms[0].room_number != sr.room_number) {
            continue;
          }
          if (x.rooms[0].id != sr.id) {
            newRooms.push(x.rooms[0]);
            await addProduct(userToken, sessionId, x.rooms[0].id, 0, false, sr.id);
          }
          break;
        }
      }

  

      const basketDp = await loadBasket();
      if(basketDp != null){
        updatePricePlan(sessionId, basketDp.basket.price_deposit_diff);
      }

      setRoomDetailsFiltered(availableRooms);

      // Update state with selected board
      setSelectedBoardId(board.id);
      sessionStorage.setItem('selectedBoard', board.id.toString());

      setBoardDisabled(false);
      setRoomDisabled(false);
    }
  };

  const onFlightSelect = async (flight: Flight) => {

    sessionStorage.setItem('selectedFlight', flight.id.toString())

    const basketDp = await loadBasket();
    if(basketDp != null){
      updatePricePlan(sessionId, basketDp.basket.price_deposit_diff);
    }
  }

  const onRoomSelect = async (room: Room) => {

    setRoomDisabled(true);
    const basketDp = await loadBasket();
    if(basketDp != null){

      const selProds = [];
      for(const x of basketDp.rooms){
        selProds.push(x.id);
      }
      sessionStorage.setItem('selectedRooms', selProds.join('-'));
      sessionStorage.setItem('selectedBoard', room.board.toString());
      updatePricePlan(sessionId, basketDp.basket.price_deposit_diff);
    }
    setRoomDisabled(false);
  };

  const updatePricePlan  = (sessionId: any, basketPrice: any) => {
    paymentPayMonthly(userToken, 1, sessionId, basketPrice).then((pp) => {
      if(pp == null){
        return;
      }
      if(pp.basket_payment_plan == null){
        return;
      }
      if(pp.basket_payment_plan.result == null){
        return;
      }
      setPaymentPlan(pp.basket_payment_plan.result)
    });
  }



  useEffect(() => {
    async function ini() {


      const aid = sessionStorage.getItem('selectedAccommodationID');
      if(aid == null){
        return null;
      }

   

      if(selectedSearchParams == null){
        return;
      }
      const adults = adultCountFromRooms(selectedSearchParams.rooms);
      const children = childCountFromRooms(selectedSearchParams.rooms);
      const childAges = childAgesFromRooms(selectedSearchParams.rooms);


      const jse = sessionStorage.getItem('js1');
      if(jse == null){
        journeyAdd(Number(aid), adults, children, selectedSearchParams.rooms.length, '', 'Flight & Room', 1, sessionId || '', selectedSearchParams.searchType, userToken);
        sessionStorage.setItem('js1', '1');
      }

  
      const depCode = sessionStorage.getItem('departure_code');
      const arrCode = sessionStorage.getItem('arrival_code');
      const departing = sessionStorage.getItem('departing');

      if(depCode == null){
        return null;
      }
      if(arrCode == null){
        return null;
      }
      if(departing == null){
        return null;
      }

      await getFlights(selectedSearchParams.duration, depCode, arrCode, departing, adults, childAges);
      setFlightLoaded(true);
 
      // const arrival = sessionStorage.getItem('arrival');
      // if(arrival == null){
      //   return null;
      // }

      const strRooms = roomsToStringURL(selectedSearchParams.rooms);
      const rd = await getRooms(selectedSearchParams.duration, departing, strRooms, selectedBoardId, Number(aid));

      const basket = await loadBasket();

      let providerID = 4;
      if(basket != null){
        for(const x of basket.basket.products){
          if(x.product_type.id == 0){
            providerID = x.provider_id;
          }
        }
      }

      const a = await getAccommodation(userToken, Number(aid), providerID);
      if(a == null){
        return null;
      }

      setAccommodation(a);
      
      const defaultRooms: Room[] = [];
      setRoomLoaded(true);
    }

    if (sessionId) {
      ini();
    }
  }, []);

  // useEffect(() => {
    
  //   for(const x of roomData){
  //     if(x.rooms.length == 0){
  //       return
  //     }
  //   }

  //   const availableRooms = roomData.map((item) => {
  //     return {
  //       ...item,
  //       rooms: item.rooms.filter((room) => room.board === selectedBoardId),
  //     };
  //   });

  //   setRoomDetailsFiltered(availableRooms);
  // }, [roomData]);

  useEffect(() => {
    if(basket != null){
      const sess = sessionId;
      if(sess == null){
        return
      }
      updatePricePlan(sessionId, basket.price_deposit_diff);
    }
  }, [everythingLoaded]);

  const getRooms = async (duration: number, date: string, rooms: string, desiredBoard: number, accommodationId: number) => {

    const data = await productRooms(userToken, sessionId, accommodationId, dayjs(date).format('YYYY-MM-DD 00:00'), duration, rooms, desiredBoard);

    let firstId = 0;

    if (data.rooms.result) {
      const roomsData = data.rooms.result;
      const roomsBoardsAsso: Board[] = [];

      roomsData.map((data: RoomGroup, index: number) => {
        return {
          ...data,
          rooms: data.rooms.map((room: Room) => {


            if(room.board != null){
 
              if (!firstId) firstId = room.id;

              let f = false;
              for(const b of roomsBoardsAsso){
                if(room.board == b.id){
                  f = true;
                  if (room.price_per_person < b.price) {
                    b.price = room.price_per_person;
                  }
                  break;
                }
              }
  
              if (!f) {
                roomsBoardsAsso.push({id:room.board, price: room.price_per_person, diff: 0, diffStr: '', type: boardFromID(room.board)});
              }
  
  
              return {
                title: room.description,
                type: room.board_description,
                price: room.price_per_person,
                id: room.id,
                boardId: room.board,
                accommodatiomId: room.accommodation_id,
                roomIndex: index + 1,
              };
            }

          }),
        };
      });

   

      
      for(const rd of roomsData){
        if(rd.rooms.length == 0){
          return null;
        }
      }
  
      let availableRooms = roomsData.map((item) => {
        return {
          ...item,
          rooms: item.rooms.filter((room) => room.board === selectedBoardId),
        };
      });

      const cheapestBoard = roomsData[0].rooms[0].board;

      if(availableRooms.length == 0){
        availableRooms = roomsData.map((item) => {
          return {
            ...item,
            rooms: item.rooms.filter((room) => room.board === cheapestBoard),
          };
        });
      }

      setBoardDetails(roomsBoardsAsso);
      setRoomData(roomsData);
      setRoomDetailsFiltered(availableRooms);

      if (sessionId != null) {
        const r = await sessionSave(userToken, sessionId, selectedSearchParams.searchType);
        const ob = {
          reference: r,
          steps: [1],
          email: '',
          contactEmail: '',
          contactNumber: '',
          type: selectedSearchParams.searchType
        }
        sessionStorage.setItem('urf' + sessionId, JSON.stringify(ob))
        
      }
      return roomsData;
    }
    return null;
 
  };

  const getFlights = async (duration: number, departurePointcode:string, arrivalPointCode: string, departureDate: string, adults: number, childAges: number[] ) => {
    
    const d = await productFlights(userToken, sessionId || '', duration, arrivalPointCode, departurePointcode, dayjs(departureDate).format("YYYY-MM-DD 00:00"), adults, childAges);

    if (d.flights?.result) {
      for (const flight of d.flights.result) {
        flightData.push(flight);
      }

      if (sessionId) {

        setFlightData(flightData);
        setFlightLoaded(true);
        await loadBasket();
      }
    }
  };

  const loadBasket = async () => {
    if (sessionId) {
      const basketDp =await getBasketDP(userToken, sessionId);

      if(basketDp == null){
        return null;
      }



      setBasket(basketDp.basket);
      setBasketRooms(basketDp.rooms);
      if(basketDp.flight != null){
        setBasketFlight(basketDp.flight);
      }
      setBasketTransfer(basketDp.transfer);

      return basketDp;
    }
  };

  const nextStep = () => {
    // default the payment back to 3 (full price)
    // setPaymentMethod(3);
    navigate(`/extras${location.search}`);
  };


  const noRooms = () => {
    const lastHol = sessionStorage.getItem('lastHolidayPage');
    const lastSearchRes = sessionStorage.getItem('lastSearchResults');
    let lastHolEl = null;
    if(lastHol != null){
      lastHolEl = <>Tap or Click <a href={lastHol}>here</a> to try a different offer for this accommodation.<br></br><br></br></>
    }  
    let lastSearchEl = null;
    if(lastSearchRes != null){
      lastSearchEl = <>Tap or Click <a href={lastSearchRes}>here</a> to try a different holiday.</>
    }
    return (
      <div className={`loader-page-container ${fadeOut ? 'loader-page-fadeout' : ''}`}>
        <img className='loader-page-logo' src={logoSrc} height={100} />
  
        <div className='loader-container'>
  
          <div style={{padding: '50px 50px 50px 50px'}}>
              So sorry we cant find live availabiltiy for this offer at the moment.<br></br><br></br>
  
              This can be due to a lack of availabiliy , or no suitable rooms are available for your party.<br></br>.<br></br>
  
              {lastHolEl}
              {lastSearchEl}
  
  
          </div>
      </div>
  
      </div>
    );

  }


 


  if (flightLoaded && roomLoaded && !everythingLoaded) {
    fadeOut = true;
    setTimeout(() => setEverythingLoaded(true), 1000);
  }

  if (!flightLoaded || !roomLoaded || !everythingLoaded) {
    return loadingScreen();
  }

  if(basket == null){
    return loadingScreen();
  }
  if(basketRooms == null){
    return noRooms();
  }
  if(basketRooms.length == 0){
    return noRooms();
  }


  let selectedRoomPrices = 0;
  for (const sr of basketRooms) {
    selectedRoomPrices += sr.price_per_person;
  }

  const bbPrices = [];
  for (let i = 0; i < 6; i++) {
    let totalBbPrice = 0;
    for (const x of roomData) {
      let roomBbPrice = 0;


      for (const r of x.rooms) {
        if (r.board == i) {
          if (roomBbPrice != 0) {
            if (r.price_per_person < roomBbPrice) {
              roomBbPrice = r.price_per_person;
            }
          } else {
            roomBbPrice = r.price_per_person;
          }
        }
      }
      // roomBbPrice = roomBbPrice / roomDetails.length;
      totalBbPrice += roomBbPrice;
    }
    bbPrices.push({ bb: i, totalBbPrice });
  }

  for (const bbp of bbPrices) {
    // work out the diff
    let diff = 0;
    let strDiff = '';
    if (selectedRoomPrices > bbp.totalBbPrice) {
      diff = Number((selectedRoomPrices - bbp.totalBbPrice));
      diff = (diff / roomData.length)

      strDiff = 'From - £' + diff.toFixed(2) + 'pp';
    } else if (selectedRoomPrices < bbp.totalBbPrice) {
      diff = Number((bbp.totalBbPrice - selectedRoomPrices));
      diff = (diff / roomData.length)

      strDiff = 'From + £' + diff.toFixed(2) + 'pp';
    } else {
      diff = 0;
      strDiff = '';
    }

    for (const bd of boardDetails) {
      if (bd.id == bbp.bb) {
        bd.diff = diff;
        bd.diffStr = strDiff;
        break
      }
    }
  }

  let gotRooms = true;
  for(const x of roomData){
    if(x.rooms.length == 0){
      gotRooms = false;
      break;
    }
  }


  ProgressBarDefault.forEach((progress) => {
    progress.isActive = false;
    progress.isCompleted = false;
  });

  ProgressBarDefault[0].isActive = true;

  let bask = null;
  let baskDesk = null;

  if(accommodation == null){
    return null;
  }

  bask = <Basket basketData={basket} pricePlan={pricePlan} selectedFlight={basketFlight || null} selectedRooms={basketRooms || []} selectedTransfer={basketTransfer} sessionId={sessionId} selectedAccommodation={accommodation} searchedRooms={selectedSearchParams.rooms} />;
  baskDesk = <Basket basketData={basket}  pricePlan={pricePlan} selectedFlight={basketFlight || null} selectedRooms={basketRooms || []} selectedTransfer={basketTransfer} sessionId={sessionId} selectedAccommodation={accommodation} searchedRooms={selectedSearchParams.rooms} >
  <Button
    label={'Continue'}
    primary={true}
    isTransparent={false}
    onClick={() => {
      nextStep();
    }}
    backgroundColor={configuration.button_colour}
    borderColor={configuration.button_colour}
  />
</Basket>

let flightCom = null;

if(basketFlight && flightData){
  flightCom = <SelectFlights sessionId={sessionId} selectedFlight={basketFlight} basketData={basket} title={'Select Flights'} titleImage={bigPlane} flights={flightData} 
  onSelect={(flight:Flight) => onFlightSelect(flight)} />
}

const adultCount = adultCountFromRooms(selectedSearchParams.rooms);
const childAges = childAgesFromRooms(selectedSearchParams.rooms);
let childCount = 0;
for(const a of childAges){
  if(a >= 2){
    childCount++;
  }
}
let paxCount = adultCount + childCount;

  return (
    <BaseLayout
      progressBar={ProgressBarDefault}
      onSearchButtonClick={() => setIsSearchBarOpen(!isSearchBarOpen)}
      isNavigationVisible={false}
      userToken={userToken}
      userConfiguration={configuration}
    >
      <div className='select-page-container'>
        <div className='select-page-col select-page-col-basket select-page-col-basket-mobile'>
          {basket && bask}
        </div>
        <div className='select-page-col'>
          <div style={{borderColor:configuration.jrny.border_colour}} className='select-page-container-select-flights'>
            {flightCom}
          </div>

          <div className='select-page-divider' />

          <div style={{borderColor:configuration.jrny.border_colour}} className='board-and-rooms-container'>
            {boardDetails && (
              <div className='select-page-board-details'>
                <h4 className='board-title'>
                  <img className='board-icon' src={food} height='20' width='20'></img>
                  <span className='board-title-text'>Select Board</span>
                </h4>

                <SelectBoard
                  onClick={filterBoard}
                  selectedBoadId={selectedBoardId}
                  boards={boardDetails}
                  disabled={boardDisabled}
                />
              </div>
            )}

            {roomDetailsFiltered.length > 0 && (
              <AccommodationSelection
                sessionId={sessionId}
                selectedRooms={basketRooms}
                groups={roomDetailsFiltered}
                disable={roomDisabled}
                onSelect={(rooms: Room) => onRoomSelect(rooms)}
                totalPax={paxCount}
              />
            )}
          </div>

          <div className='select-page-divider'></div>
          <div className='select-page-continue-mobile'>
            <Button
              label={'Continue'}
              primary={true}
              isTransparent={false}
              onClick={() => {
                navigate(`/extras${location.search}`);
              }}
              backgroundColor={configuration.button_colour}
              borderColor={configuration.button_colour}
            />
          </div>
        </div>

        <div className='extras-page-col extras-page-col-basket'>
          {baskDesk}
        </div>
      </div>
    </BaseLayout>
  );
};

export default observer(SelectPage);
