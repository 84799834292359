// @ts-ignore
import gtag from 'ga-gtag';

import { Offer, Basket, Room, Flight, Transfer, Package, Accommodation } from '../hooks/types'
import { off } from 'process';

const packageToGoogleItem = (offer: Package, offerPrice: number) => {
    const o = {
        item_id: 'Package',
        item_name: offer.accommodation.name,
        item_brand: 'Jet 2 Holidays',
        item_category: offer.accommodation.resort.regions[0].destinations[0].name,
        item_category2: offer.accommodation.resort.regions[0].name,
        item_category3: offer.accommodation.resort.name,
        item_category4: offer.accommodation.name,
        item_variant: offer.rooms[0].board_description,
        price: offerPrice,
        quantity: 1
    }
    return o;
}

const offerToGoogleItem = (offer: Offer) => {
    const o = {
        item_id: "Offer",
        item_name: offer.accommodation.name,
        item_brand: 'HotelBeds',
        item_category: offer.accommodation.resort.regions[0].destinations[0].name,
        item_category2: offer.accommodation.resort.regions[0].name,
        item_category3: offer.accommodation.resort.name,
        item_category4: offer.accommodation.name,
        price: offer.price,
    }
    return o;
}

const roomToGoogleItem = (room: Room, acc: Accommodation, price: number) => {
    const r = {
        item_id: 'Hotel',
        item_name: acc.name,
        item_brand: 'HotelBeds',
        item_category: acc.resort.regions[0].destinations[0].name,
        item_category2: acc.resort.regions[0].name,
        item_category3: acc.resort.name,
        item_category4: acc.name,
        item_variant: room.board_description,
        price: price,
        quantity: 1
    }
    return r;
}

const flightExtrasToItems = (flight: Flight) => {
    const items: any[] = [];
    for(const x of flight.extras){
        const f = {
            item_id: 'Flight Extra',
            item_name: "Flight Extra",
            item_brand: flight.airline + ' ' + flight.supplier_code,
            item_category: x.description,
            quantity: 1
        }
    }
    return items;
}

const flightToGoogleItem = (flight: Flight) => {
    const f = {
        item_id: 'Flight',
        item_name: "Flight",
        item_brand: flight.airline,
        item_category: flight.departure_airport.name + ' (' + flight.departure_airport.code + ')',
        item_category2: flight.arrival_airport.name + ' (' + flight.arrival_airport.code + ')',
        item_category3: '',
        item_category4: '',
        item_category5: '' ,
        quantity: 1
    }
    return f;
}

const transferToGoogleItem = (transfer: Transfer) => {
    const t = {
        item_id: 'Transfer',
        item_name: 'Transfer',
        item_brand: 'HTX',
        item_category: transfer.vehicle_type,
        item_category2: '',
        item_category3: '',
        item_category4: '',
        item_category5: '' ,
        quantity: 1
    }
    return t;
}



export function viewItemPackage(pakOffer: Package, price: number, paxcount: number){
    if(gtag == null){
        return
    }

    try{
        const items:any[] = [];
        const o = packageToGoogleItem(pakOffer, price);
        items.push(o);
       
        gtag("event", "view_item", {
            currency: "GBP",
            items: items
        })
    }
    catch (error) {
        console.log(error);
    }

    
}

export function viewItem(offer: Offer){
    if(gtag == null){
        return
    }

    try {
        const items:any[] = [];
        const o = offerToGoogleItem(offer);

        const room = roomToGoogleItem(offer.rooms[0], offer.accommodation, offer.price)
        const flight = flightToGoogleItem(offer.flights[0])

        items.push(room);
        items.push(flight);

        gtag("event", "view_item", {
            currency: "GBP",
            items: items
        })
    }
    catch (error) {
        console.log(error);
    }


}

export function viewItemList(offers: Offer[]){
    if(gtag == null){
        return
    }

    try{
        const items:any[] = [];
        for(const o of offers){
            const offer = offerToGoogleItem(o);
            const room = roomToGoogleItem(o.rooms[0], o.accommodation, o.price)
            items.push(room);
        }
        gtag("event", "view_item_list", {
            currency: "GBP",
            items: items
        })
    }
    catch (error) {
        console.log(error);
    }
}

export function selectItems(offers: Offer[]){
    if(gtag == null){
        return
    }

    try{
        const items:any[] = [];
        for(const o of offers){
            const offer = offerToGoogleItem(o);
            const room = roomToGoogleItem(o.rooms[0], o.accommodation, o.price)
            const flight = flightToGoogleItem(o.flights[0])
            items.push(room);   
            items.push(flight);
        }
        gtag("event", "select_item", {
            currency: "GBP",
            items: items
        })
    }    
    catch (error) {
        console.log(error);
    }
    

}

export function selectItemsPackage(offers: Package[], price: number, paxcount: number){
    if(gtag == null){
        return
    }

    try{
        const items:any[] = [];
        for(const o of offers){
            const offer = packageToGoogleItem(o, price);
            items.push(offer);
        }
        gtag("event", "select_item", {
            currency: "GBP",
            items: items
        })
    }    
    catch (error) {
        console.log(error);
    }



}


export function addToCart(basket: Basket, acc: Accommodation, adults: number, children: number, room?: Room[], flights?: Flight|null, tranfer?: Transfer|null){
    if(gtag == null){
        return
    }

    const paxCount = adults + children;
    try{
        const items:any[] = [];
        if(room != null){
            for(const r of room){
                items.push(roomToGoogleItem(r, acc, basket.price));
            }
        }
        if(flights != null){
            items.push(flightToGoogleItem(flights));
            const extras = (flightExtrasToItems(flights));
            for(const x of extras){
                items.push(x);
            }
        }
        if(tranfer != null){
            items.push(transferToGoogleItem(tranfer));
        }
       
        gtag("event", "add_to_cart", {
            currency: "GBP",
            affiliation: 'Hays Travel/HWT',
            value: basket.price,
            items: items
        })
    }
    catch (error) {
        console.log(error);
    }
}


export function purchaseObject(basket: Basket, acc: Accommodation, room?: Room[], flights?: Flight|null, tranfer?: Transfer|null){
    try{
        const items:any[] = [];
        if(room != null){
            // for(const r of room){
            //     items.push(roomToGoogleItem(r, acc, basket.price));
            // }
            if(room.length > 0){
                items.push(roomToGoogleItem(room[0], acc, basket.price));
            }
   
        }
        if(flights != null){
            items.push(flightToGoogleItem(flights));
            const extras = (flightExtrasToItems(flights));
            for(const x of extras){
                items.push(x);
            }
        }
        if(tranfer != null){
            items.push(transferToGoogleItem(tranfer));
        }
       
        return{
            currency: "GBP",
            transaction_id: basket.booking?.reference + '-' + basket.booking?.id,
            affiliation: 'Hays Travel/HWT',
            value: basket.price,
            items: items
        }
    }
    catch (error) {
        console.log(error);
    }
}

export function purchase(basket: Basket, acc: Accommodation, room?: Room[], flights?: Flight|null, tranfer?: Transfer|null){
    if(gtag == null){
        return
    }

    try{
        const items:any[] = [];
        if(room != null){
            // for(const r of room){
            //     items.push(roomToGoogleItem(r, acc, basket.price));
            // }
            if(room.length > 0){
                items.push(roomToGoogleItem(room[0], acc, basket.price));
            }
   
        }
        if(flights != null){
            items.push(flightToGoogleItem(flights));
            const extras = (flightExtrasToItems(flights));
            for(const x of extras){
                items.push(x);
            }
        }
        if(tranfer != null){
            items.push(transferToGoogleItem(tranfer));
        }
       
        gtag("event", "purchase", {
            currency: "GBP",
            transaction_id: basket.booking?.reference + '-' + basket.booking?.id,
            affiliation: 'Hays Travel/HWT',
            value: basket.price,
            items: items
        })
    }
    catch (error) {
        console.log(error);
    }
}

export function addToCartPackage(basket: Basket, pakOffer: Package){
    if(gtag == null){
        return
    }

    try{
        const items:any[] = [];
        if(pakOffer != null){
            items.push(packageToGoogleItem(pakOffer, basket.price));
        }
        gtag("event", "add_to_cart", {
            currency: "GBP",
            affiliation: 'Hays Travel/HWT',
            value: basket.price,
            items: items
        })
    }
    catch (error) {
        console.log(error);
    }
}

export function purchasePackageObject(basket: Basket, pakOffer: Package){

    try{
        const items:any[] = [];
        if(pakOffer != null){
            items.push(packageToGoogleItem(pakOffer, basket.price));
        }
        return {
            currency: "GBP",
            transaction_id: basket.booking?.reference + '-' + basket.booking?.id,
            affiliation: 'Hays Travel/HWT',
            value: basket.price,
            items: items
        }
    }
    catch (error) {
        console.log(error);
    }
}

export function purchasePackage(basket: Basket, pakOffer: Package){
    if(gtag == null){
        return
    }

    try{
        const items:any[] = [];
        if(pakOffer != null){
            items.push(packageToGoogleItem(pakOffer, basket.price));
        }
        gtag("event", "purchase", {
            currency: "GBP",
            transaction_id: basket.booking?.reference + '-' + basket.booking?.id,
            affiliation: 'Hays Travel/HWT',
            value: basket.price,
            items: items
        })
    }
    catch (error) {
        console.log(error);
    }
}

export function enableAnal(){
    if(gtag == null){
        return
    }
    try{
        gtag('consent', 'default', {
            'analytics_storage': 'granted'
        });
    }
    catch (error) {
        console.log(error);
    }
}