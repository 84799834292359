import React, { FunctionComponent } from 'react';
import { clearSessionKeys } from '../../services/session';

interface LogoProps {
  width: number;
  height: number;
  route: string;
  configuration?: any;
}

export const Logo: FunctionComponent<LogoProps> = ({ width, height, route, configuration }: LogoProps) => {


  const LogoClick = () => {
    clearSessionKeys(['snpOff', 'f', 'aprm', 'js1', 'js2', 'js3', 'js4', 'js5', 'shosnt', 'gap']);

    if(configuration != null){
      location.href =  configuration.home_url;
    }
    else{
      location.href = '#'
    }
  }

  let logoSrc = 'https://booking.haystravel.co.uk/static/media/hays_logo.svg'
  if(configuration != null){
    logoSrc = configuration.logo_file;
  }

  return (
    <>
      <a href='#' onClick={LogoClick}>
        <img width={width} height={height} src={logoSrc} alt="Logo icon"/>
      </a>
    </>
  )
}
